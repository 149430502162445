import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { hasValue } from '@digital-magic/ts-common-utils'
import { CreateLayoutTypeFreeOptionRequest, LayoutTypeFreeOptionView } from '@api/endpoints/buildings/layoutTypes'
import { useCreateLayoutTypeFreeOption, useDeleteLayoutTypeFreeOption } from '@api/endpoints/buildings/layoutTypes/api'
import { OptionId, useGetOptions } from '@api/endpoints/buildings/options'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Delete } from '@mui/icons-material'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Form, FormSelectField, useFormTyped } from '@controls/Form'
import { Text } from '@controls/Text'
import { MenuItemEntry } from '@controls/types'
import { useLayoutTypeFormContext } from '../LayoutTypeFormContext'
import { LayoutTypeFreeOptionsStyled } from './LayoutTypeFreeOptions.styles'

const LayoutTypeFreeOptionFormValues = CreateLayoutTypeFreeOptionRequest.pick({
  optionId: true
})
type LayoutTypeFreeOptionFormValues = z.infer<typeof LayoutTypeFreeOptionFormValues>

export const LayoutTypeFreeOptions: React.FC = () => {
  const { t } = useTranslation()
  const { layoutType } = useLayoutTypeFormContext()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const freeOptions = React.useMemo(
    () => layoutType.freeOptions.concat().sort((a, b) => a.code.localeCompare(b.code)),
    [layoutType]
  )
  const paidOptions = React.useMemo(
    () => layoutType.options.concat().sort((a, b) => a.code.localeCompare(b.code)),
    [layoutType]
  )

  const layoutTypeId = layoutType.id

  const getOptions = useGetOptions(undefined, { onError: defaultErrorHandler })
  const createFreeOption = useCreateLayoutTypeFreeOption({ onError: defaultErrorHandler })
  const deleteFreeOption = useDeleteLayoutTypeFreeOption({ onError: defaultErrorHandler })

  const isLoading = getOptions.isFetching || createFreeOption.isLoading || deleteFreeOption.isLoading

  const onSubmit = (values: LayoutTypeFreeOptionFormValues): void => {
    if (layoutTypeId) {
      createFreeOption.mutate({ ...values, layoutTypeId }, { onSuccess: () => form.reset() })
    }
  }

  const onDeleteFreeOption =
    (option: LayoutTypeFreeOptionView) =>
    (confirmResult: boolean): void => {
      if (layoutTypeId && confirmResult) {
        deleteFreeOption.mutate({ layoutTypeFreeOptionId: option.id, layoutTypeId })
      }
    }

  const form = useFormTyped({
    resolver: LayoutTypeFreeOptionFormValues,
    onSubmit
  })

  const selectOptions = React.useMemo(
    (): ReadonlyArray<MenuItemEntry<OptionId>> =>
      getOptions.data
        ?.filter(
          (option) =>
            !freeOptions.some((freeOption) => option.id === freeOption.optionId) &&
            !paidOptions.some((paidOption) => option.id === paidOption.optionId)
        )
        .sort((a, b) => a.code.localeCompare(b.code))
        .map((o) => ({ label: `${o.code}: ${o.name.eng}`, value: o.id })) ?? [],
    [getOptions.data, freeOptions, paidOptions]
  )

  const isDisabled = !hasValue(layoutTypeId) || selectOptions.length === 0

  return (
    <LayoutTypeFreeOptionsStyled>
      <Text size="size-24" weight="regular">
        {t('pages.admin.layout_type.free_options.title')}
      </Text>
      {selectOptions.length === 0 && freeOptions.length === 0 ? (
        <Text component="div" weight="regular" mt={2} size="size-18">
          {t('pages.admin.layout_type.free_options.messages.nothing_to_add')}
        </Text>
      ) : (
        <>
          <ul>
            {freeOptions.map((o) => (
              <li key={o.code}>
                <ButtonWithConfirmation
                  color="error"
                  onConfirmResult={onDeleteFreeOption(o)}
                  confirmTitle={t('global.consent.delete.title')}
                  confirmMessage={t('global.consent.delete.message')}
                >
                  <Delete />
                </ButtonWithConfirmation>
                {`${o.code}: ${o.name.eng}`}
              </li>
            ))}
          </ul>
          {!isDisabled && (
            <Form f={form}>
              <FormSelectField
                fullWidth
                label={t('pages.admin.layout_type.free_options.form.field.option')}
                options={selectOptions}
                name={form.names.optionId}
                disabled={isLoading}
              />
              <Button type="submit" disabled={isLoading}>
                {t('pages.admin.layout_type.free_options.buttons.add')}
              </Button>
            </Form>
          )}
        </>
      )}
    </LayoutTypeFreeOptionsStyled>
  )
}
